tbody, td, tfoot, th, thead, tr {
  
  border-style: none !important;
 
}
.thead {
  background-color: #007bff;
  color: #fff;
}
.text_right {
  text-align: right;
}
.text_left {
  text-align: left;
}
.section {
  padding: 30px 0px;
  position: relative;
}
.mb_20 {
  margin-bottom: 20px;
}
.form-label {
  padding-top: 5px;
}
.table {
  margin: 0px !important;
}
.box {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ccc;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
  min-width: 150px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  position: relative;
  max-width: 216px;
  margin: 0 auto;
}
.login-box {
  display: block;
  text-align: center;
  background-color: #ccc;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  position: relative;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ccc;
}
.loader_div {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.error_msg {
  color: #ff0000;
  font-size: 14px;
  text-align: left;
}
.btn-primary,
.thead {
  background-color: #646c64 !important;
  border: #646c64 !important;
}
.box.active {
  /* background-color: #981A01; */
  background-color: #009e35;
  color: #fff;
}
.btn {
  box-shadow: none !important;
  border: 0px;
  outline: 0px !important;
}

.pdf_btn {
  background-color: #981a01 !important;
  border: #981a01 !important;
}
.d_none {
  display: none;
}

@media (max-width: 576px) {
  .cus_res_table .thead {
    display: none;
  }
  .cus_res_table tr {
    margin-bottom: 20px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #ddd;
    display: block;
  }
  .cus_res_table td,
  .cus_res_table th {
    display: inline-block;
    width: 50%;
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .container,
  .container-sm {
    max-width: 100% !important;
  }
  .navbar {
    position: sticky !important;
    top: 0px;
    z-index: 9;
    background-color: #fff;
  }
  .carousel-control-prev-icon {
    filter: invert(1);
    height: 20px !important;
    width: 20px !important;
    position: relative;
    top: 5px;
  }
  .backBtn {
    position: absolute;
    left: 0px;
    top: 10px;
    text-decoration: none !important;
    color: #000 !important;
  }
  .navbar-brand img {
    height: 35px !important;
  }
  .view_details {
    position: relative;
    left: 35px;
  }
}

@media (max-width: 1024px) {
  .section {
    padding: 60px 0px;
  }
  .logout_btn {
    position: absolute;
    right: 10px !important;
    top: 10px;
  }
  .navbar-brand img {
    height: 60px;
  }
}

.loader_box {
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
}

.mt_20 {
  margin-top: 20px;
}
.mb_40 {
  margin-bottom: 40px;
}
.logout_btn {
  position: absolute;
  right: 0px;
}
